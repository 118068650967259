<template>
  <div class="wrap">
    <div class="space left"></div>
    <div class="radio-detail-wrapper">
      <Nav :cateSub="info.cate_sub" />
      <div class="body">
        <div class="back-title">
          <span>所有课程</span>
          <i class="iconfont icon-class-right"></i>
          <span>{{ info.cate_sub_name }}</span>
          <i class="iconfont icon-class-right"></i>
          <span>{{ info.name }}</span>
        </div>

        <div class="line"></div>

        <div class="radio-detail">
          <div class="left">
            <template v-if="info.sign_up == 1"><p class="title">恭喜您，已成功报名以下直播课程！</p></template>
            <template v-else>
              <button class="btn" @click="handleSignup">立刻报名</button>
            </template>
            <p>
              课程时间：{{
                (info.live_stime * 1000) | timeFormat('YYYY-MM-DD HH:mm:ss')
              }}
            </p>
            <p>授课老师：{{ (info.teacher_info || {}).name }}</p>
            <p>授课方式：ZOOM直播</p>
            <p>课程费用：<span>免费</span></p>
          </div>
          <div class="right">
            <div class="img">
              <img :src="helperInfo.site_val" alt="" />
            </div>
            <div>
              <div>添加PTEGO小助手</div>
              <div>加入直播互动群</div>
            </div>
          </div>
        </div>

        <el-tabs class="tabs">
          <el-tab-pane label="课程详情">
            <div class="content ql-snow">
              <div class="ql-editor" v-html="info.remark"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="听课方式">
            <div class="listen-class-way">
              <div class="text">
                <p>
                  本课程将在Zoom Cloud
                  Meeting会议平台上进行（以下简称Zoom），软件下载地址为：Zoom.us/downloads，下载页面如图：
                </p>
                <p style="text-align: center"><img :src="img1" alt="" /></p>

                <p>Zoom在Windows及Mac系统中均可使用。</p>
                <br />
                <p>
                  此外，Zoom也提供了手机端/iPad等版本，大家可以在手机商店中搜索下载，如图：
                </p>
                <p style="text-align: center"><img :src="img2" alt="" /></p>
                <br />
                <p>
                  每节课开课前，老师会提前5-10分钟在班级群内发布会员ID，大家只需输入该ID，即可进入教室听课，无需注册。
                </p>
                <p>
                  如遇到任何问题，请扫描本页面右上角二维码，添加PTEGO学姐微信进行咨询，或搜索微信ID：FeifanEnglish006。
                </p>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>

      </div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
import Nav from '../SkillIndex/nav'
import { apiCourseDetial, apisignUp } from '@/api/course'
import { mapState } from 'vuex'

import img1 from '@/assets/liveCourseImg1.jpg'
import img2 from '@/assets/liveCourseImg2.jpg'

export default {
  components: {
    Nav
  },
  data() {
    return {
      info: {}
    }
  },
  computed: {
    ...mapState('app', {
      helperInfo: (state) => state.helperInfo
    }),
    img1() {
      return img1
    },
    img2() {
      return img2
    }
  },
  created() {
    this.fetchInfo()
  },
  methods: {
    async fetchInfo() {
      const { skillId } = this.$route.query
      if (!skillId) {
        return false
      }

      const { data } = await apiCourseDetial({
        course_id: skillId
      })
      this.info = data
    },
    handleSignup() {
      apisignUp({ course_id: this.info.id }).then((res) => {
        if (res.code === 200) {
          this.$message.success({
            message: res.msg,
            type: 'success'
          })

          this.fetchInfo()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  min-height: calc(100vh - 66px);
  .space {
    flex: 1;
    &.left {
      background-color: #f8f8f8;
    }
  }
  .radio-detail-wrapper {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    .body {
      flex: 1;
      margin-left: 46px;
      margin-top: 25px;
    }
    .back-title {
      font-size: 14px;
      color: #a5a5ac;
      display: flex;
      padding-top: 10px;
      align-items: center;
      > span {
        &:last-child {
          color: #1f1f40;
        }
        margin-right: 5px;
      }

      > i {
        margin-right: 5px;
      }
    }

    .line {
      border-bottom: 1px solid #ebebf2;
      margin: 18px 0;
    }

    .radio-detail {
      background: #f9f9f9;
      opacity: 1;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 28px;
      .left {
        flex: 1;
        font-size: 14px;
        .btn {
          outline: none;
          border: none;
          width: 114px;
          height: 34px;
          background: #3B86FF;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
          border-radius: 50px;
          font-size: 18px;
          color: #fff;
          margin-bottom: 10px;
          cursor: pointer;
        }
        > p {
          line-height: 30px;
          &.title {
            font-weight: bold;
            margin-bottom: 6px;
          }
          > span {
            color: #ff606d;
          }
        }
      }
      .right {
        text-align: center;
        margin-left: 20px;
        > div {
          color: #676767;
          font-size: 12px;
        }
        .img {
          > img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }

    .listen-class-way {
      .title {
        font-weight: bold;
        margin: 22px 0;
      }
      .text {
        color: #616161;
        line-height: 2;
        > img {
          margin: 0 auto;
        }
      }
    }
    .tabs {
      margin-top: 10px;
      ::v-deep .el-tabs__active-bar {
        background-color: #3B86FF;
      }
      ::v-deep .el-tabs__item {
        &.is-active {
          color: #3B86FF;
        }
        &:hover {
          color: #3B86FF;
        }
      }

      .content {
        margin-top: 10px;
        margin-bottom: 100px;
        color: #1f1f40;
      }
    }
  }
}
</style>
